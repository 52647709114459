<template>
  <div class="tableForm">
    <el-table
      :data="myData"
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#fafafa',
        color: '#000000',
        'font-weight': 400,
      }"
      class="elTable"
      ref="elTable"
      @selection-change="selectChange"
    >
      <!-- <el-table-column type="selection"></el-table-column> -->
      <!-- <el-table-column prop="serialNumber" label="流水号">
				<template slot-scope="scope">
					<span class="serialNumber">{{
            $utils.map.parseTime(scope.row.serialNumber)
          }}</span>
				</template>
			</el-table-column> -->
      <!-- <el-table-column prop="title" label="标题"> </el-table-column> -->
      <el-table-column
        prop="name"
        label="产品"
        min-width="20%"
      ></el-table-column>
      <el-table-column prop="sampleName" label="样本名称" min-width="15%">
      </el-table-column>
      <el-table-column prop="sampleType" label="样本类型" min-width="10%">
      </el-table-column>
      <el-table-column prop="sampleSendMethod" label="送样方式" min-width="15%">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="16%">
        <template slot-scope="scope">
          <span>{{ $utils.map.parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" min-width="14%" fixed>
        <template slot-scope="scope">
          <span>{{ $utils.map.parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operation"
        label="操作"
        align="right"
        min-width="10%"
      >
        <template slot-scope="scope">
          <div class="operation">
            <span class="item" @click="handleModifyDraft(scope.row)">
              <span class="title">编辑</span></span
            >
            <!-- <span class="item" @click="handleModifyDraft(scope.row)">
              <i class="iconfont el-icon-my-xiugai"></i
              ><span class="title">修改</span></span
            > -->
            <span class="item" @click="handleDeleteDraft(scope.row)">
              <span class="title" style="color: red">删除</span></span
            >
            <span class="item" @click="handleCopyDraft(scope.row)">
              <span class="title">复制</span></span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改订单弹窗 -->
    <apt-create-order
      v-model="showCreateOrder"
      :data="query"
      title="新建订单"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import {
  getDraftDetail,
  deleteDraft,
  copyDraft,
  submitDraft,
} from "@/api/order/draft";
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // myData: this.data,
      selected: [], //当前选中项
      showCreateOrder: false, //是否展示复制订单弹窗
      query: null, //修改弹窗传入参数
    };
  },
  computed: {
    myData() {
      return this.data;
    },
  },
  methods: {
    selectChange(val) {
      this.selected = val;
    },
    // 删除订单
    handleDelete() {
      if (this.selected.length > 1) {
        this.$message({
          type: "warning",
          message: "一次只能删除一条订单",
        });
      } else if (this.selected.length === 0) {
        this.$message({
          type: "warning",
          message: "请先选择一条订单以便删除",
        });
      } else {
        this.handleDeleteDraft(this.selected[0]);
      }
    },
    // 复制下单
    copy() {
      if (this.selected.length > 1) {
        this.$message({
          type: "warning",
          message: "复制下单只能选择一条订单",
        });
      } else if (this.selected.length === 0) {
        this.$message({
          type: "warning",
          message: "请先选择一条订单以便复制下单",
        });
      } else {
        this.handleCopyDraft(this.selected[0]);
      }
    },
    // 加载提交订单
    handleSubmitOrder(row) {
      this.$messageBox
        .confirm("此操作将提交该订单，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const id = row.id;
          getDraftDetail(id).then((data) => {
            this.startSubmitOrder(data);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
          });
        });
    },
    // 加载删除订单
    handleDeleteDraft({ id }) {
      this.$messageBox
        .confirm("确认是否删除该条记录，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          deleteDraft(id).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.$emit("handleGetOrderLsit");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 加载修改草稿
    handleModifyDraft(row) {
      const id = row.id;
      this.query = {
        id: id, //订单ID
        type: "modifyDraft", //操作类型
      };
      this.showCreateOrder = true;
    },
    // 展开复制弹窗
    handleCopyDraft({ id }) {
      this.$messageBox
        .confirm("此操作将复制该订单，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = {
            orderId: id,
          };
          copyDraft(params).then((res) => {
            this.$message({
              type: "success",
              message: "复制草稿订单成功",
            });
            this.$emit("handleGetOrderLsit");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
          });
        });
    },
    // 开始提交草稿
    startSubmitOrder(data) {
      submitDraft(data).then(() => {
        this.$message({
          type: "success",
          message: "提交订单已成功",
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.tableForm {
  .elTable {
    .demoName {
      color: $theme_color;
      cursor: pointer;
      align-items: center;
    }

    .serialNumber {
      color: $theme_color;
    }

    .operation {
      color: $theme_color;
      @include end;
      // flex-wrap: wrap;
      position: relative;

      .item_viewReport {
        .popup {
          display: flex;
          visibility: hidden;
          position: absolute;
          bottom: -178px;
          left: 0;
          width: 110px;
          height: auto;
          z-index: 3;
          border-radius: 5px;
          background-color: #fff;
          flex-wrap: wrap;
          transition: all 0.1s ease-in-out;
          background-color: #fff;
          border-radius: 2 px;
          outline: none;
          box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
            0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

          .popup_item {
            color: #222222;
            font-size: 14px;
            @include center;
            width: 100%;
            padding: 6px 0;

            &:hover {
              background: $theme_color;
              color: #ffffff;
            }

            &:first-child:hover {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            &:last-child:hover {
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;

        .title,
        .iconfont {
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }

        .iconfont {
          width: 16px;
          height: 18px;
        }
      }

      .more {
        cursor: pointer;
        color: $theme_color;
        font-size: 14px;
      }
    }
  }
}
</style>
