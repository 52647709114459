<template>
  <div class="draft_wrap">
    <div class="draft elTab">
      <el-row class="tab_header">
        <el-col :span="24">
          <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane :name="myRoute.draft">
              <span slot="label">草稿箱&nbsp;&nbsp;{{ total }}</span>
            </el-tab-pane>
            <el-tab-pane label="订单列表" :name="myRoute.order"></el-tab-pane>
            <el-tab-pane
              v-if="myRoute.history"
              label="2022.7.18前订单"
              :name="myRoute.history"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <query-form
        :data="form"
        @search="search"
        @copy="copy"
        @delete="handleDelete"
      />
      <table-form
        :data="tableData"
        @handleGetOrderLsit="handleGetOrderLsit"
        ref="tableForm"
        @success="handleGetOrderLsit"
      />
      <div class="elPagination">
        <el-pagination
          v-if="tableData.length > 0"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { submitDraft, getDraftList, getDraftCount } from "@/api/order/draft";
import queryForm from "./queryForm.vue";
import TableForm from "./tableForm.vue";
export default {
  name: "draft",
  components: {
    queryForm,
    TableForm,
  },
  props: {
    route: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      active: this.route.draft, //当前激活页
      tableData: [], //表格数据
      pageNum: 1, //当前页
      pageSizes: [12, 24, 36], //分页数量
      pageSize: 12, //分页数量
      total: 0, //数据合计
      form: {
        selectValue: "sampleName", //查询类型
        searchValue: "", //查询值
      }, //查询参数
      myRoute: this.route, //tab路由
      myData: this.data, //接收父组件传递过来的参数
    };
  },
  watch: {
    route(newVal) {
      this.myRoute = newVal;
    },
    data(newVal) {
      this.myData = newVal;
    },
  },
  methods: {
    //获取草稿箱订单总数量
    getDraftTotalAmount() {
      getDraftCount().then((data) => {
        this.total = data;
      });
    },
    // 删除订单
    handleDelete() {
      this.$refs.tableForm.handleDelete();
    },
    // 复制下单
    copy() {
      this.$refs.tableForm.copy();
    },
    search(val) {
      this.pageNum = 1;
      this.pageSize = 12;
      this.form.selectValue = val.selectValue;
      this.form.searchValue = val.searchValue;
      this.handleGetOrderLsit();
    },
    handleInput(obj) {
      this.form.searchValue = obj.searchValue;
    },
    // 提交(订单 | 草稿)
    startSubmitOrder(res) {
      //ordSample-样品信息, ordDetection-检测信息
      const { ordSample, ordDetection, order, dynamicDataList } = res;
      const params = {
        batchNo: ordSample.batchNo, //样品批号
        concentration: ordSample.concentration, //浓度；单位：mg/ml
        count: ordSample.count, //样品数量
        dynamicDataList: dynamicDataList, //检测信息动态字段
        expiryDate: ordSample.expiryDate, //有效期
        gelType: ordSample.gelType, //凝胶类型； 1：1D银染。2：1D考染。3：2D银染。4：2D考染
        isKeepingSample: ordDetection.isKeepingSample, //是否留样；1：不留样。2：留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）
        orderId: order.id, //订单ID
        productId: order.productId, //产品id
        purity: ordSample.purity, //纯度
        pvdf: ordSample.pvdf, //PVDF膜；1：考染。2：丽春红
        remark: ordDetection.remark, //备注
        reportLanguage: ordDetection.reportLanguage, //报告语言；1：中文。
        reportMedium: ordDetection.reportMedium, //报告介质；1：电子版。2：纸质版。3：两者皆需要。
        reportVersion: ordDetection.reportVersion, //报告版本；1：CMA报告+APT报告。2：中科新生命版 (APT版)。
        residualSampleDispose: ordDetection.residualSampleDispose, //余样处理；1：不退样。2：退样
        sampleName: ordSample.sampleName, //样品名称
        samplePackage: ordSample.samplePackage, //样品包装；1：离心管。2：玻璃瓶。3：塑料容器。4：其他。
        sampleSendMethod: ordDetection.sampleSendMethod, //送样方式；1：物流送样。2：客户送样。3：委托抽样（需另外支付费用）。
        sampleSource: ordSample.sampleSource, //样品来源；1：生产。2：购买（请提供原包装信息）。3：其他（备注里请明确）。
        sampleType: ordSample.sampleType, //样品类型；1：小肽类。2：重组蛋白。3：单抗类。4：其他类。
        specs: ordSample.specs, //样品规格；1：原料药。2：化合物。3：成品药。4：中间体。5：其他。
        state: ordSample.state, //样品状态；1：固体。2：液体。3：凝胶。4：PVDF膜。
        storageConditions: ordSample.storageConditions, //贮存条件；1：室温。2：2-8℃。3：-15至-25℃。4：-65至-85℃
        testType: ordDetection.testType, //检测类别；1：委托。2：出口。3：复核。4：其他。
        trait: ordSample.trait, //样品性状；1：无色澄清液体。2：淡黄色澄清液体。3：白色固体粉末。4：类白色固体粉末。5：其他。
        volume: ordSample.volume, //体积
        weight: ordSample.weight, //重量；单位：mg
      };
      submitDraft(params).then((res) => {
        this.$message({
          type: "success",
          message: "提交成功",
        });
      });
    },
    // 改变页数
    handleCurrentChange(val) {
      this.pageNum = val;
      this.handleGetOrderLsit();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleGetOrderLsit();
    },
    handleClick(tab, event) {
      this.$router.push(`${this.active}`);
    },
    //获取草稿订单列表
    handleGetOrderLsit() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        [this.form.selectValue]: this.form.searchValue,
        type: this.$utils.storage.get("user").userBstp,
      };
      const params2 = this.$utils.map.getNotEmptyObj(params);
      getDraftList(params2).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },
  },
  created() {
    this.handleGetOrderLsit();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";

.draft_wrap {
  padding: 15px 25px;
  flex-grow: 1;

  .draft {
    background: #ffffff;
    border-radius: 3px;
    min-height: 100%;
    padding: 0 20px 90px;
    position: relative;
    box-sizing: border-box;

    .btn_wrap {
      margin: 10px 0 25px;
    }

    .tab_header {
      & ::v-deep .el-tabs__item {
        font-size: 14px;
      }
    }
  }
}

/*样式穿透*/
.elPagination {
  @include end;
  position: absolute;
  bottom: 25px;
  right: 25px;
  // .total {
  //   font-family: "PingFangSC-Light", "PingFang SC Light", "PingFang SC",
  //     sans-serif;
  //   font-weight: 200;
  //   font-style: normal;
  //   color: rgba(0, 0, 0, 0.647058823529412);
  //   font-size: 14px;
  //   margin: 0 5px;
  // }
}

.elTable ::v-deep .cell {
  overflow: visible;
}

.dialogCopy ::v-deep .el-dialog__body {
  @include center;
}
</style>
