import { getUploadPath } from "@/api/file/index";

// 用户信息
export const mixinUser = {
  data() {
    return {
      user: null, //用户数据
      userBstp: null, //用户业务类型
      userBstpSelected: undefined, //用户选择业务类型
    };
  },
  methods: {
    // 获取用户数据
    getUserData() {
      this.user = this.$utils.storage.get("user");
      this.userBstp = this.user.businessTypes.map((item) => item.businessType);
      this.userBstpSelected = this.user.userBstpSelected;
    },
  },
};

// 上传组件
export const mixinUpload = {
  data() {
    return {
      // 上传成功以后获取图片参数
      uploadSuccess: {
        img: "", //上传图片成功回调参数
      },
      // 上传组件参数
      uploadParams: {
        header: {
          Authorization: this.$utils.storage.get("user").token,
        }, //上传文件请求头
        data: {
          type: 0,
        }, //上传图片参数
        action: "", //上传图片服务器地址
        accept: "image/jpeg,image/jpg,image/png,image/gif" //图片上传格式
      },
    };
  },
  methods: {
    // 获取上传图片接口地址
    handleGetUploadPath() {
      return getUploadPath(this.uploadParams.data).then((data) => {
        this.uploadParams.action = data;
      });
    },
    // 图片上传成功回调
    updateFile(file, fileList) {
      const imgUrls = [];
      console.log(fileList)
      fileList.forEach((item) => {
        //fileList里包含了返显数据，需要判断
        if (item.response && item.response.data) {
          imgUrls.push(item.response.data);
        }
      });
      this.uploadSuccess.img = imgUrls.join(",");
    },
  },
};
