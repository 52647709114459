<template>
  <div class="queryForm">
    <apt-select-search
      v-bind.sync="myData"
      :selectList="queryParamsList"
      @search="search"
      @clear="clear"
      class="queryParams"
      ref="aptSelectSearch"
    />
    <el-button style="height: 36px; margin-left: 8px;" @click="reset" size="medium">
      <svg-icon icon-class="reset" />
    </el-button>
    <!-- <div class="btn">
      <el-button type="primary" plain @click="openCreateOrder"
        >下单</el-button
      >
      <el-button
        type="primary"
        plain
        @click="openBatchCreateOrder"
        >批量下单</el-button
      >
      <el-button
        type="primary"
        plain
        @click="$emit('copy')"
        >复制下单</el-button
      >
      <el-button type="danger" plain @click="$emit('delete')"
        >删除</el-button
      >
    </div> -->
    <!-- 下单 -->
    <apt-create-order v-model="showCreateOrder"  title="下单" @success="search" />
    <!-- 批量下单 -->
    <apt-batch-create-order v-model="showBatchCreateOrder" />
  </div>
</template>

<script>
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      myData: this.data,
      queryParamsList: order.draftQueryParams,
      showCreateOrder: false, //展示下单弹窗
      showBatchCreateOrder: false, //展示批量下单弹窗
    };
  },
  watch: {
    data: {
      handler(newVal) {
        this.myData = newVal;
      },
    },
  },
  methods: {
    //搜索
    search(val) {
      this.$emit("search", val);
    },
    openCreateOrder() {
      this.showCreateOrder = true;
    },
    openBatchCreateOrder() {
      this.showBatchCreateOrder = true;
    },
    clear() {
      this.$refs.aptSelectSearch.search();
    },
    // 重置搜索条件并刷新
    reset() {
      this.myData.selectValue = "sampleName";
      this.myData.searchValue = "";
      this.$nextTick(() => {
        this.$refs.aptSelectSearch.search();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.queryForm {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  .queryParams {
  }
  .btn {
    @include center;
  }
}
</style>
