import { getUploadPath } from "@/api/file/index";
import { order } from "@/utils/dict";
import { getDraftCount } from "@/api/order/draft";

export const mixinOrder = {
  data() {
    return {
      tableData: [], //表格数据
      form: {
        bpOrderNo: "",
        productName: "",
        sampleName: "",
        // searchValue: "", //搜索值
        // selectValue: "bpOrderNo", //搜索字段
        state: "-1", //订单状态
        // date: [], //日期
        pageNum: 1, //当前页
        pageSize: 12, //分页数量
        businessType: undefined, //业务类型
        lotNo: "", //样本批号
      },
      pageSizes: [12, 24, 36], //分页数量
      total: 100, //数据总计
      open: false, //是否可以修改默认密码
      //上传图片参数
      upload: {
        data: {
          type: 0,
        }, //上传参数
        action: "", //上传服务器地址
        //上传文件请求头
        header: {
          Authorization: this.$utils.storage.get("user").token,
        },
      },
      showCreateOrder: false, //展示下单弹窗
      showBatchCreateOrder: false, //展示批量下单弹窗
      draftTotal: 0, //草稿箱总数量
      showCreateOrderBtn: true, //是否展示新建订单按钮
      user: this.$utils.storage.get("user"), //用户数据
      showAppraise: false, //展示评价弹窗
      appraiseData: {}, //评价组件参数
      selected: [], //当前选中列表
    };
  },
  methods: {
    //多选
    select(val) {
      this.selected = val;
    },
    // 是否展示新建订单
    handleShowCreateOrderBtn() {
      if (this.user && this.user.businessTypes) {
        const bsTypes = this.user.businessTypes.map(
          (item) => `${item.businessType}`
        );
        const productType = [order.busiType.bsgOtd]; //可以显示新建订单的业务类型
        for (const item of bsTypes) {
          if (productType.includes(item)) {
            this.showCreateOrderBtn = true;
            return;
          }
        }
      }
      this.showCreateOrderBtn = false;
    },
    // 刷新订单数据
    async refreshOrder() {
      await this.handleGetOrderLsit();
      this.getDraftTotalAmount();
    },
    // 提交订单成功回调
    submitOrderSuccess() {
      this.handleGetOrderLsit();
    },
    // 获取草稿箱订单总数量
    getDraftTotalAmount() {
      getDraftCount().then((data) => {
        this.draftTotal = data;
      });
    },
    openCreateOrder() {
      this.showCreateOrder = true;
    },
    openBatchCreateOrder() {
      this.showBatchCreateOrder = true;
    },
    copy() {
      this.$refs.orderTable.handleCopy();
    },
    // 搜索
    search(val) {
      this.form.selectValue = val.selectValue;
      this.form.searchValue = val.searchValue;
      this.handleGetOrderLsit();
    },
    // 获取上传图片接口地址
    handleGetUploadPath() {
      getUploadPath(this.upload.data).then((data) => {
        this.upload.action = data;
      });
    },
    // 判断是否可以修改默认密码
    canModifyDefaultPassword() {
      const user = this.$utils.storage.get("user");
      if (user && user.defaultPassword === 1) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
    // 改变页数
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.handleGetOrderLsit();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.handleGetOrderLsit();
    },
    handleClick(tab, event) {
      this.$router.push(`${this.active}`);
    },
    // 订单事件
    orderEvent(val) {
      const appraiseTypes = [
        "bsgOtdViewAppraise",
        "bsgOtdSubmitAppraise",
        "bsgLtcViewAppraise",
        "bsgLtcSubmitAppraise",
      ]; //评价弹窗类型
      if (appraiseTypes.includes(val.type)) {
        //订单评价相关
        this.appraiseData = val;
        this.showAppraise = true;
      }
    },
  },
  created() {
    this.handleGetUploadPath();
    this.getDraftTotalAmount();
    this.canModifyDefaultPassword();
    this.getUserData();
    this.handleShowCreateOrderBtn();
  },
};
