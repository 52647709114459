<template>
  <div class="modifyPassword" v-if="myOpen">
    <el-dialog
      title="修改默认登录密码"
      :modal="true"
      custom-class="dialog"
      :visible.sync="myOpen"
      width="400px"
    >
      <p class="desc">为了保证您的账户安全，请修改默认登录密码</p>
      <el-form
        :model="form"
        :status-icon="false"
        :rules="rules"
        ref="submit"
        class="submit"
      >
        <el-form-item prop="password" class="password">
          <el-input
            v-model="form.password"
            autocomplete="off"
            placeholder="输入6~18位新密码"
            class="password_input"
            prefix-icon="el-icon-my-mima"
            show-password
            :maxlength="18"
            :minlength="6"
          ></el-input
        ></el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click.native="confirm" type="primary" size="medium">确定</el-button>
				<el-button @click.native="cancel" size="medium">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { changeDefaultPassword } from "@/api/home/index";
import _ from "lodash";
import md5 from "md5";
export default {
  props: {
    open: Boolean,
    default: false,
  },
  watch: {
    open(newVal) {
      this.myOpen = newVal;
    },
  },
  data() {
    //密码校验
    const password = (rule, value, cb) => {
      if (value === "") {
        cb(new Error("提示：请输入6~18位新密码"));
      } else if (value.length < 6) {
        cb(new Error("提示：当前密码不足6位"));
      } else {
        cb();
      }
    };
    return {
      myOpen: this.open,
      form: {
        password: "", //验证码
      },
      rules: {
        password: [{ validator: password, trigger: "blur" }], //密码校验规则
      },
    };
  },
  methods: {
    cancel() {
      this.myOpen = false;
    },
    confirm: _.debounce(function () {
      this.$refs.submit.validate((valid) => {
        if (!valid) return;
        const params = {
          password: md5(this.form.password),
        };
        changeDefaultPassword(params).then(() => {
          const user = this.$utils.storage.get("user");
          user.defaultPassword = 0;
          this.$utils.storage.set("user", user);
          this.myOpen = false;
          this.$message({
            type: "success",
            message: "修改默认密码已成功",
          });
        });
      });
    }),
  },
};
</script>

<style lang="scss" scoped>
.modifyPassword {
  .desc {
		margin: 16px 10%;
    color: #aaaaaa;
  }
  ::v-deep .dialog {
		
    .submit {
      margin: 32px 10%;
      .el-form-item__label {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
      }
      .el-input__inner {
        height: 36px;
        font-size: 14px;
        padding-left: 36px;
        padding-right: 32px;
      }
      .password {
        .el-input__prefix {
          left: 10px;
        }
        .el-input__icon {
          position: relative;
        }
        .el-input__icon:before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 16px;
        }
      }
    }
    .btn {
      @include start;
      margin: 48px 10%;
      .cancel {
      }
    }
  }
}
</style>
