<!-- 订单详情  -->
<template>
  <el-drawer
    title="订单详情"
    :visible.sync="myOpen"
    size="85%"
    class="elDrawer"
    direction="rtl"
    @close="$emit('input', false)"
  >
    <div class="content_wrap">
      <el-descriptions class="content_item">
        <el-descriptions-item label="订单编号">{{
          myData.vbillcode
        }}</el-descriptions-item>
        <el-descriptions-item label="里程碑">{{
          myData.ismilestone
        }}</el-descriptions-item>
        <el-descriptions-item label="合同编号">{{
          myData.contractno
        }}</el-descriptions-item>
      </el-descriptions>
      <div class="fs16 tl demoList_title">样本列表</div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#fafafa',
          color: '#000000',
          'font-weight': 400,
        }"
        class="elTable"
        ref="elTable"
      >
        <el-table-column prop="materialspec" label="样本编号" min-width="120">
        </el-table-column>
        <el-table-column prop="samplename" label="样本名称"> </el-table-column>
        <el-table-column prop="materialtype" label="样品批号">
        </el-table-column>
        <el-table-column prop="characters" label="样品性状"> </el-table-column>
        <el-table-column prop="purity" label="纯度(%)"> </el-table-column>
        <el-table-column prop="concentration" label="浓度(mg/ml)">
        </el-table-column>
        <el-table-column prop="volume" label="体积(ml)"> </el-table-column>
        <el-table-column prop="weight" label="重量(mg)"> </el-table-column>
        <el-table-column prop="specification" label="样本规格">
        </el-table-column>
        <el-table-column prop="storageconditions" label="贮存条件">
        </el-table-column>
        <el-table-column prop="wrap" label="样本包装"> </el-table-column>
        <el-table-column prop="samplestatus" label="样本状态">
        </el-table-column>
        <el-table-column prop="expirationdate" label="有效期">
        </el-table-column>
      </el-table>
      <div class="elPagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="pageSizes"
          :page-size="tableParams.pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getOaOrderDetail, getPageSample } from "@/api/order/index";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myData: this.data,
      myOpen: this.value,
      tableData: null, //表格数据
      tableParams: {
        orderNo: "",
        pageNum: 1,
        pageSize: 10,
      }, //查询表单参数
      pageSizes: [10, 20, 30], //分页数量
      total: 100, //总数量
    };
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    async value(newVal) {
      this.myOpen = newVal;
      if (newVal) {
        await this.handleGetOrderDetail();
        this.getSample();
      }
    },
  },
  computed: {
    //订单编号
    // title() {
    //   return `订单编号${this.myData.vbillcode}`;
    // },
  },
  methods: {
    // 获取BSG-LTC的订单详情
    async handleGetOrderDetail() {
      await getOaOrderDetail(this.myData.vbillcode).then((res) => {
        Object.assign(this.myData, res);
        this.tableParams.orderNo = this.myData.vbillcode;
      });
    },
    // 获取样本列表数据
    getSample() {
      getPageSample(this.tableParams).then((res) => {
        this.tableData = res.sampleList;
        this.total = res.totalNum;
      });
    },
    // 改变页数
    handleCurrentChange(val) {
      this.tableParams.pageNum = val;
      this.getSample();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.tableParams.pageSize = val;
      this.getSample();
    },
  },
};
</script>
<style lang="scss" scoped>
.content_wrap {
  margin: 10px 22px;
  .demoList_title {
    border-bottom: 1px solid gray;
  }
  .content_item {
    margin-bottom: 32px;
  }
  .tsImg_wrap {
    position: relative;
    display: inline-block;
    .tsImg {
      display: flex;
      width: 100px;
      height: 100px;
      border-radius: 4px;
      box-sizing: border-box;
    }
    .el-icon-zoom-in {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  ::v-deep .el-descriptions-item__cell {
    padding-right: 20px;
  }
}
.elDrawer ::v-deep .el-drawer__header > :first-child {
  text-align: left;
}

.tsImg_dialog ::v-deep .tsImg {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

/*样式穿透*/
.elPagination {
  @include end;
  position: absolute;
  bottom: 25px;
  right: 25px;
}
</style>
