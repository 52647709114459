<template>
  <div class="queryForm">
    <el-form :inline="true" :model="myData" class="demo-form-inline">
      <div class="select-wrapper">
        <!--        <span class="select-title">状态</span>-->
        <!--        <el-divider direction="vertical"></el-divider>-->
        <el-select
          v-model="myData.state"
          size="medium"
          @change="changeOrderStatus"
        >
          <el-option
            v-for="(item, index) of orderCount"
            :key="index"
            :label="getOrderLabel(item)"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </div>

      <div>
        <div>
          <el-input
            v-model="myData.bpOrderNo"
            placeholder="订单号"
            size="medium"
            @keyup.enter.native="clickSearch"
            clearable
            class="input-style"
          ></el-input>
					<el-input
					  v-show="myData.businessType == bsgLtc"
					  v-model="myData.contractNo"
					  placeholder="合同号"
					  size="medium"
					  @keyup.enter.native="clickSearch"
					  clearable
					  class="input-style"
					></el-input>
          <el-input
            v-show="myData.businessType != bsgLtc"
            v-model="myData.productName"
            placeholder="产品名"
            size="medium"
            @keyup.enter.native="clickSearch"
            clearable
            class="input-style"
          ></el-input>
          <el-input
            v-show="myData.businessType != bsgLtc"
            v-model="myData.sampleName"
            placeholder="样本名称"
            size="medium"
            @keyup.enter.native="clickSearch"
            clearable
            class="input-style"
          ></el-input>
          <el-input
            v-show="myData.businessType != bsgLtc"
            v-model="myData.lotNo"
            placeholder="样本批号"
            size="medium"
            @keyup.enter.native="clickSearch"
            clearable
            class="input-style"
          ></el-input>
          <el-button
            slot="append"
            size="medium"
            type="primary"
            @click="clickSearch"
            >搜索</el-button
          >
        </div>
      </div>
      <el-button @click="reset" size="medium" style="margin-left: 10px;">
        重置
      </el-button>
			<el-button @click="handleExport" size="medium" style="margin-left: 10px;" v-show="myData.businessType != bsgLtc">
			  导出
			</el-button>
      <!-- <div class="createTime">
				<span class="label">创建时间</span>
				<el-date-picker size="medium" v-model="myData.date" type="daterange" align="right" unlink-panels range-separator="~"
					start-placeholder="开始时间" end-placeholder="结束时间" prefix-icon="" :picker-options="pickerOptions">
				</el-date-picker>
			</div> -->
    </el-form>
  </div>
</template>

<script>
import { order } from "@/utils/dict";
import { getOrderStatusCount, exportBsgOtdOrderList } from "@/api/order/index";

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      myData: this.data,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      orderDict: order, //订单字典
      // queryParamsList: order.queryParams, //查询条件列表
      orderCount: [], //订单状态数量列表
    };
  },
  watch: {
    data: {
      handler(newVal) {
        this.myData = newVal;
      },
    },
  },
  computed: {
    //表格数据
    myTableData() {
      return this.tableData;
    },
    //BSG-LTC字典值
    bsgLtc() {
      return this.orderDict.bstpObj.bsgLtc.value;
    },
  },
  methods: {
    // 点击搜索按钮
    clickSearch(val) {
      console.log(val);
      this.myData.pageSize = 12;
      this.myData.pageNum = 1;
      this.search(val);
    },
    // 重置搜索条件并刷新
    reset(val) {
      this.myData.state = "-1";
      this.myData.bpOrderNo = "";
      this.myData.productName = "";
      this.myData.sampleName = "";
      this.myData.lotNo = "";
      this.search(val);
    },
    // 改变订单状态
    changeOrderStatus() {
      this.myData.pageSize = 12;
      this.myData.pageNum = 1;
      this.$emit("search", this.myData);
    },
    // 查询订单状态数量
    handleGetOrderStatusCount() {
      const params = {
        type: this.$utils.map.getUserBstpNo(),
      };
      getOrderStatusCount(params).then((data) => {
        this.orderCount = data;
        this.orderCount.unshift({
          key: "-1",
          count: 0,
        });
      });
    },
		// 导出订单
		handleExport() {
			// 由于导出为单独提供接口，故此处只能将页面设置较大值以请求当前的分页接口处理数据导出
			this.myData.pageSize = 10000;
			this.myData.pageNum = 1;
			exportBsgOtdOrderList(this.myData).then((res) => {
				let blob = new Blob([res.data], { type: 'application/xlsx;charset=utf-8' });
				let url = window.URL.createObjectURL(blob);
				//创建a标签
				const link = document.createElement('a'); 
				link.href = url;
				//重命名文件
				// 获取当前时间戳并格式化，然后添加到文件名中
				let timestamp = new Date().toISOString().replace(/[:\-]/g, '').replace('T', '_').replace(/\..+/, '');
				link.download = `订单数据_${timestamp}.xlsx`;
				link.click();
				URL.revokeObjectURL(url);
			}).catch((err) => {
				console.log(err);
			}).finally(() => {
        // 无论成功或失败，都重置pageSize和pageNum
        this.myData.pageSize = 12; // 将每页数据量修改回12
        this.myData.pageNum = 1; // 将当前页码修改回1
			});
		},
    //搜索
    search(val) {
      this.$emit("search", val);
    },
    //获取每个状态的条数
    getOrderLabel({ key, count }) {
      const ignoreKeys = ["31", "32", "33"]; //计算全部数量时，忽略的状态值
      let name;
      //现在后端返回的是字符串
      // key = Number(key);
      for (const item of this.orderDict.status2) {
        if (item.value == key) {
          name = item.name;
          break;
        } else if (item.value == -1) {
          name = item.name;
        }
      }
      if (key == -1) {
        //全部订单
        let total = 0;
        for (const item2 of this.orderCount) {
          if (!ignoreKeys.includes(item2.key)) {
            total += item2.count;
          }
        }
        return `${name} ${total}`;
      } else if (name) {
        //其它订单
        return `${name} ${count}`;
      }
    },
  },
  created() {
    this.handleGetOrderStatusCount();
  },
};
</script>

<style lang="scss" scoped>
.queryForm {
  .demo-form-inline {
    @include start;
    // flex-wrap: wrap;
    display: flex;
    flex-direction: row;

    .select-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 240px;
      margin-right: 10px;
      height: 36px;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;

      .select-title {
        // margin: auto 15px;
        font-size: 14px;
        color: #909399;
      }

      .el-select {
        ::v-deep .el-input__inner {
          width: 240px;
          height: 36px;
          border: 1px solid #dcdfe6;
        }
      }
    }

    .input-style {
      width: 200px;
      margin-right: 10px;
    }

    .queryParams {
      margin-left: 10px;
      margin-right: 10px;
    }

    .createTime {
      margin-left: 20px;
      @include center;

      .label {
        @include center;
        border: 1px solid $theme_bd_color;
        color: #606266;
        line-height: 1;
        white-space: nowrap;
        vertical-align: middle;
        background: #fff;
        font-weight: 500;
        width: 100px;
        font-size: 14px;
        border-right: 0;
        box-sizing: border-box;
        height: 32px;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      ::v-deep .el-input__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .queryForm {
    .demo-form-inline {
      .createTime {
        margin-left: 0;
      }
    }
  }
}
</style>
